import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import DrivingSustainableChangeDetails from "./components/DrivingSustainableChangeDetails";

export default function DrivingSustainableChange() {
  return (
    <Layout>
      <NewsHeader>
        Bridging Impact and Investment: Driving Sustainable Change
      </NewsHeader>
      <DrivingSustainableChangeDetails />
    </Layout>
  );
}
