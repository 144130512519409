import React from "react";
import NewsHeader from "../../../shared/Components/NewsHeader";
import Layout from "../../../shared/Layout/Layout";
import NeverPromisedDetails from "./components/NeverPromisedDetails";

export default function NeverPromised() {
  return (
    <Layout>
      <NewsHeader>
        “Tomorrow is never promised, Today is too short, Do not just dream, make
        some legacies!”
      </NewsHeader>
      <NeverPromisedDetails />
    </Layout>
  );
}
