import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news176.png";
import news123_creator from "../../../../assets/images/news176_creator.png";
import news176_creator2 from "../../../../assets/images/news176_creator2.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ImpactInvestmentStoryDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Bangladesh is at a pivotal moment where it will need to balance
          economic growth with social development and inclusion. In this piece,
          Lily Van Berkel and Nick Goryl from XSPI highlight the role that
          impact investing can play in navigating this key moment in
          Bangladesh’s rising economy. In particular, they examine recent moves
          by BRAC and the National Advisory Board for Impact Investment in
          Bangladesh (NAB), before unpacking the case study of Build Bangladesh.
        </p>
        <p className="pb-6">
          For decades, Bangladesh has been leading a site of innovative
          development approaches, and it is now showing the signs that it is
          also ripe for impact investment. Some of the very first pioneers of
          impact investment made their mark in Bangladesh. For example,{" "}
          <a
            href="https://en.wikipedia.org/wiki/Muhammad_Yunus"
            target="_blank"
            className="underline"
          >
            Muhammed Yunus’
          </a>{" "}
          grassroots microfinance movement and the Grameen Bank demonstrated the
          vast opportunities available in Bangladesh’s social impact sphere in
          the 1980s. Yunus’s award-winning microfinance scheme, which saw small
          loans lent to communities to help them commence or run their own
          businesses as an avenue out of poverty, paved the way for the
          evolution of modern impact investment opportunities. The{" "}
          <a
            href="https://grameenbank.org.bd/"
            target="_blank"
            className="underline"
          >
            Grameen Bank
          </a>{" "}
          initiative also highlighted how Bangladesh’s government and
          communities were open to innovative ways of leveraging private capital
          for social impact.
        </p>
        <p className="pb-6">
          With projections to become the{" "}
          <a
            href="https://www.weforum.org/stories/2019/10/bangladesh-ict-development-economic-growth/"
            target="_blank"
            className="underline"
          >
            24th
          </a>{" "}
          largest economy by 2033, and with a growing population of 170 million,
          today, Bangladesh has never been so ready for further business and
          investment. In fact, over the past decade, the key industries
          underpinning Bangladesh’s economy have boomed, driven largely by
          growth in Bangladesh’s ready-made garment sector. The sector’s current
          value is US$47 billion and is aiming to reach{" "}
          <a
            href="https://consulting.groyyo.com/bangladeshs-garment-sector-stitching-success-to-achieve-100-billion-by-2023/#:~:text=Garment%20Sector%20of%20Bangladesh%3A%20%24100%20Billion%20Target%20by%202030"
            target="_blank"
            className="underline"
          >
            US$100 billion
          </a>{" "}
          in value in 2030.
        </p>
        <p>
          However, as Bangladesh’s market expands, so do calls for women’s
          rights and equality in the industry. A changing regulatory landscape
          in the context of instances of{" "}
          <a
            href="https://www.iixglobal.com/wp-content/uploads/2011/07/22Feb_Bangladesh%E2%80%99s-entry-into-impact-investment.pdf"
            target="_blank"
            className="underline"
          >
            female worker exploitation
          </a>{" "}
          have highlighted that Bangladesh is at a pivotal moment where it will
          need to balance economic growth with social development and inclusion.
          This is no simple task and Bangladesh’s civil society is working hard
          to achieve democratic progress whilst government, local, and
          international non-government initiatives are concurrently{" "}
          <a
            href="https://www.iixglobal.com/wp-content/uploads/2011/07/22Feb_Bangladesh%E2%80%99s-entry-into-impact-investment.pdf"
            target="_blank"
            className="underline"
          >
            working to build stronger commitments
          </a>{" "}
          to labour rights and ethical working practices. In this context,
          Bangladesh has become uniquely positioned for investors wanting to
          make a difference in the impact investment space.
        </p>
        <p className="font-semibold pt-6"> Why Impact Investment? </p>
        <p className="">
          Contrary to standard forms of investment, impact investment concerns
          itself with social benefit as well as financial profit. The{" "}
          <a
            href="https://thegiin.org/publication/post/about-impact-investing/"
            target="_blank"
            className="underline"
          >
            Global Impact Investing Network
          </a>{" "}
          (GIIN) defines impact investing as ‘investments made with the
          intention to generate positive, measurable social and environmental
          impact alongside a financial return’. When investing with the
          intention of generating impact, investors facilitate atmospheres of
          growth, security and sustainability for the people and communities
          they choose to invest in.
        </p>
        <p className="">
          Recently, large development organisations such as{" "}
          <a href="https://www.brac.net/" target="_blank" className="underline">
            BRAC
          </a>{" "}
          , have realised the{" "}
          <a
            href="https://www.tandfonline.com/doi/full/10.1080/09614524.2023.2273756"
            target="_blank"
            className="underline"
          >
            ‘mutually reinforcing objectives’
          </a>{" "}
          of the investment-development nexus, with established investments in
          the sectors of sustainability, housing, technology, and employment.
          Through such investments, BRAC has succeeded in the ‘localisation’ of
          funds and enabled community and national organisations to drive
          Bangladesh’s social development progress, rather than allow this
          sector to be overshadowed by international entities. This{" "}
          <a
            href="https://www.tandfonline.com/doi/full/10.1080/09614524.2023.2273756"
            target="_blank"
            className="underline"
          >
            localisation
          </a>{" "}
          drives lasting and sustainable change.
        </p>
        <p className="pb-6">
          Additionally, establishment of the National Advisory Board for Impact
          Investment in Bangladesh{" "}
          <a
            href="https://nabbangladesh.org/"
            target="_blank"
            className="underline"
          >
            (NAB)
          </a>{" "}
          , has seen both{" "}
          <a
            href="https://lightcastlepartners.com/insights/2022/10/revolutionizing-bangladeshs-impact-story-through-impact-investments/#:~:text=The%20growth%20of%20impact%20investment,with%20deep%20intentionality%20and%20contextualization."
            target="_blank"
            className="underline"
          >
            social and environmental advocacy
          </a>{" "}
          joining Bangladesh’s foreign investment agenda. The NAB launched the
          Bangladesh Impact Investment Strategy and Action Plan (BIISAP) in 2022
          to guide the Impact Investment Ecosystem development process in
          Bangladesh. Rather than ignoring social inequality and environmental
          damage,{" "}
          <a
            href="https://lightcastlepartners.com/insights/2022/10/revolutionizing-bangladeshs-impact-story-through-impact-investments/#:~:text=The%20growth%20of%20impact%20investment,with%20deep%20intentionality%20and%20contextualization."
            target="_blank"
            className="underline"
          >
            emerging impact investors in Bangladesh
          </a>{" "}
          are supporting civil minorities by using their position to enable
          infrastructure projects that prioritise environmental sustainability.
        </p>

        <p className="font-semibold">Build Bangladesh</p>
        <p className="">
          One entity that has been leading the Bangladesh impact investment
          scene since 2016 is Build Bangladesh. With close ties to{" "}
          <a href="https://xspi.org/" target="_blank" className="underline">
            XSPI
          </a>{" "}
          , Build Bangladesh is an innovative organisation working to mainstream
          impact investment in the country. In 2017, Build Bangladesh identified
          that Bandarban, a regional community in Bangladesh, had limited access
          to safe drinking water. Women and girls were travelling a long
          distance to collect water, which was often contaminated with bacteria,
          and the community faced extreme water scarcity due to prolonged dry
          seasons.
        </p>
        <p className="">
          In response to this identified problem, Build Bangladesh commenced the
          <a
            href="https://thegiin.org/publication/research/landscape-southeast-asia/#:~:text=The%20Landscape%20for%20Impact%20Investing%20in%20Southeast%20Asia%20provides%20detailed,Singapore%2C%20Thailand%2C%20and%20Vietnam."
            target="_blank"
            className="underline"
          >
            Prokriti o Paani project
          </a>{" "}
          a financial investment project with a compelling social outcome. The
          project utilized locally sourced resources and community groups to
          build sustainable water towers. This project increased the community’s
          access to clean water, saved over 61,000 collective hours that were
          previously spent on water collection, and empowered women and girls to
          access income and education opportunities.This project and others have
          established Build Bangladesh as an innovative financing mechanism with
          development progress integrated into its business model.
        </p>
        <p className="">
          Build Bangladesh is now one of many exciting impact investing
          organisations emphasising the untapped opportunities present in the
          Bangladesh impact investment market, and showcasing how investors can
          support social and environmental progress whilst simultaneously
          receiving economic returns.
        </p>
        <p className="py-6 font-bold">Moving Forward</p>
        <p className="pb-6">
          With the nation’s rapidly growing and diversifying economy and
          population, Bangladesh’s impact investment sector is on the precipice
          of expansion. Australian donors, investors, and those already focused
          on impact investing have an incredible opportunity to mobilise their
          financial investments in support of a positive social return for our
          Bangladeshi neighbours. To continue to facilitate social and
          environmental progress alongside economic growth, it is key that
          Bangladesh, alongside Australian impact investors, work together to
          expand this impact investment sector.
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Nick Goryl</p>
            <p>Bangladesh Working Group Member &</p>
            <p>Impact Investing Taskforce Lead</p>
            <p>Cross Sector Development Partnerships</p>
            <p>Initiative (XSPI)</p>
          </div>
        </div>
        <div className="mb-8">
          <img
            src={news176_creator2}
            alt=""
            className="max-w-[500px] mx-auto"
          />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Lily Van Berkel</p>
            <p>Research Associate</p>
            <p>Cross Sector Development Partnerships</p>
            <p>Initiative (XSPI)</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
