import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news175.png";
import news123_creator from "../../../../assets/images/news_175Creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function DrivingSustainableChangeDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Sustainable and impact mainstreaming continues to draw more people to
          apply their experience and knowledge to direct resources and capital
          towards real world results.
        </p>
        <p className="pb-6">
          Pressing social and environmental challenges can only be addressed if
          we acknowledge our role in finding solutions, our agency, and a
          capacity to work in partnership. This belief has driven my interest in
          bridging commercial and impact experience to help deliver meaningful
          change in business, bring impact into strategy, and advocate for
          others to become change-makers too.
        </p>
        <p className="pb-6">
          My ‘impact journey’ has been built with institutional investors – both
          insurance company asset owners – in QBE Insurance Group’s early,
          innovative and successful impact investment program, responsible and
          climate commitments, and Prudential plc’s investment target for
          Financing the Transition for 2030, with a focus on EM. Each case
          demonstrates that institutional asset owners can invest for impact and
          sustainable outcomes, aligning strategy and value.
        </p>
        <p>
          Like many people in the impact ecosystem, over time, building a
          portfolio of skills for meaningful impact, I navigated the path into
          impact investment by bridging private sector commercial experience,
          civil-society experience, economics, specific social impact and
          systems-focused education, and personal experience.
        </p>
        <p className="py-6">
          Corporate roles in the private sector at Thomson (now Refinitiv),
          Deloitte and QBE were valuable in developing commercial experience in
          research, commercial negotiation, data & risk operations, as well as
          organisation and change management.
        </p>
        <p className="pb-6">
          Impact has been the golden thread - even while pursuing degrees in
          Economics (Sydney) and a MA in International Relations (UNSW). Early
          formative experiences, including time in Thailand as a teenager,
          sparked a focus on social impact, regional and global business,
          particularly in Asia.
        </p>
        <p className="">
          A volunteer contribution with IFBPW, a global civil-society
          organisation focussed on empowerment of women and girls, broadened my
          exposure and experience as a change-maker at local and system levels.
          I worked closely with the International Executive in early-opportunity
          identification and endorsement of the then-new UN Womens Empowerment
          Principles (WEPs) in 2008-9, and attended UN Commission on the Status
          of Women in New York. It was valuable experience which underpins my
          work in gender lens investing.
        </p>
        <p className="pb-6">
          As I was searching for a method to bridge commercial, government and
          third sector, while fostering innovation, I was fortunate to be
          selected into the first cohort at the Centre for Social Impact at UNSW
          in 2010. The Centre for Social Impact was established to catalyse
          social change for a better world – including Leadership for Social
          Change, education about the work of leading innovators such as Prof
          Mohamad Yunus, and leading impact investors.
        </p>
        <p className="py-6 font-bold">QBE - Impact Investing Experience</p>
        <p className="pb-6">
          My entry-point into impact investing was in the same corporate group.
          QBE made a commitment of USD100m to suitable social impact investments
          in 2014, and was an early supporter of Impact Investing Australia, the
          Australian National Advisory Board (NAB), national member of GSG
          Impact. In 2014, while at QBE, I was rapidly seconded to (and then
          joined) Group Investments on social impact investments; working with
          Heads of, and the GCIO, a crucial sponsor for QBE’s impact investing
          program, and on responsible investment.
        </p>
        <p className="">
          The initiative, Premiums4Good, integrated social value with business
          value, by connecting insurance customer premiums to a growing demand
          for impact investments. It grew from an initial commitment to USD100m
          to USD 1.4bn by 2021.
        </p>
        <p className="">
          The intent in Premiums4Good was to demonstrate an institutional
          approach to impact investment across asset classes and to signal
          institutional demand for impact investment, and to seek out
          innovation. We set ambitious targets of USD1 bn by 2021 and USD2 bn by
          2025 – more than 5% of the portfolio. The program included governance
          with internal and external expert members, oversight review to impact
          frameworks, investment eligibility, monitoring and reporting. It is an
          early example consistent with the calls now for asset owners to
          consider 5% and 10% portfolio allocation to impact by 2030.
        </p>
        <p className="">
          With that commitment to innovation, I gained experience in specialist
          impact investment products, in social impact bonds (SIBs). QBE made a
          specific commitment in 2014 with the intent to lead as an investor to
          support evidence- and outcomes-based impact investments. By June 2021,
          QBE were invested, either direct or fund, in approximately 10% of SIBs
          issued globally, in themes such as education, homelessness, youth
          employment, reduced reoffending, health, and had anchored the first
          SIB Fund in USA.
        </p>
        <p className="">
          As a demonstration of the value of a focus on impact and sustainable
          business, Premiums4Good was a catalyst for many positive changes and
          generated pride in the active role in impact investing and
          sustainability. It has helped QBE rethink the role it can play in
          creating a more resilient, inclusive and sustainable low-carbon
          economy. It positioned the organisation on a positive change
          trajectory.
        </p>
        <p className="py-6 font-semibold">
          Prudential plc and Climate Transition Focus
        </p>
        <p className="pb-6">
          In 2023-4, I had the opportunity to work with Prudential plc in
          Singapore. Building on Prudential's climate net zero commitments and
          RI strategy, in that short time, we convened a cross-team
          collaboration that delivered a significant investment target for 2030
          for Financing the Transition. It underpins the organisation’s
          commitment to Just & Inclusive Transition, and puts it firmly on a
          positive trajectory alongside QBE.
        </p>
        <p className="">
          And I continue to work with organisations to advance RI and impact
          strategy, IMM and implementation to turn initial interest, mandates
          and commitments into action and implementation.
        </p>
        <p className="py-6 font-semibold">Ecosystem</p>
        <p className="pb-6">
          As a beneficiary of exposure to impact leaders, social finance
          intermediaries, and impact innovators, and the inspiration that
          catalysed, I aim to contribute to the industry so that we collectively
          see growth: as representative (QBE) on an Australian Impact Investing
          Advisory Board (AIIB) Working Group; input to industry standards or
          tools in development; and as previous Co-Chair for the Impact
          Management & Measurement Community of Practice (IMMCOP) at Responsible
          Investment Association Australasia (RIAA), which encouraged asset
          owners and endowments to engage with rapidly developing practice in
          impact – to move more capital to real-world outcomes.
        </p>
        <p className="">
          Impact investment, responsible investment, and sustainable business
          are now mainstream.
        </p>
        <p className="font-semibold py-6">
          It is through these diverse experiences that, I have seen first-hand
          the difference that a focus on impact, impact investing and
          sustainable business makes – for the business and its strategy; its
          people, customers, investors, people and planet. A focus on impact
          delivers value in multiple domains.
        </p>
        <p className="">
          Asset owners, institutional investors and funders have a such central
          role in securing this value as they integrate and embed impact and
          sustainability considerations into business.
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Caitlin Medley</p>
            <p>Principal</p>
            <p>Medley Impact</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
