import React from "react";
import { Link } from "react-router-dom";
import news171 from "../../../../assets/images/news177.png";
import news171_people from "../../../../assets/images/news177_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ImpactInvestmentSrilankaDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news171}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="font-semibold">
          {" "}
          Pioneering Change-How LIIN Shaped Sri Lanka’s Impact Investing
          Landscape
        </p>
        <p className="pb-6">
          In a world where social and environmental challenges grow increasingly
          complex, the power of impact investing has emerged as a beacon of
          hope, driving capital toward ventures that deliver measurable social
          and environmental benefits. In Sri Lanka, this transformative movement
          found its footing through the Lanka Impact Investing Network (LIIN), a
          trailblazer in fostering a vibrant ecosystem for impact
          entrepreneurship and investment.
        </p>
        <p className="font-semibold">
          The Genesis of LIIN- Catalyzing a New Wave of Investment
        </p>
        <p className="">
          LIIN was born out of a vision to redefine how capital could serve
          society. Founded by a group of passionate individuals who believed in
          the potential of business to drive positive change, LIIN aimed to fill
          a critical gap in Sri Lanka’s entrepreneurial ecosystem. At the time
          of its inception, the concept of impact investing was relatively
          nascent in the country. Entrepreneurs with socially driven missions
          struggled to secure funding, and investors were wary of ventures that
          prioritized impact alongside financial returns.
        </p>
        <p className="pb-6">
          LIIN stepped into this void with a clear mission: to bridge the gap
          between capital and impact-driven ventures. Through its pioneering
          efforts, LIIN introduced Sri Lanka to a new investment paradigm—one
          that balanced profit with purpose. It identified, nurtured, and
          connected early-stage social enterprises with investors who shared a
          commitment to creating lasting social value.
        </p>
        <p className="font-semibold">
          Building the Ecosystem- Empowering Impact Entrepreneurs
        </p>
        <p className="">
          Over the years, LIIN’s work has been instrumental in building a robust
          ecosystem for impact entrepreneurship in Sri Lanka. The organization
          provided more than just funding; it offered mentorship,
          capacity-building programs, and a platform for collaboration. By
          fostering a community of like-minded individuals and organizations,
          LIIN empowered entrepreneurs to scale their innovations and amplify
          their impact.
        </p>
        <p className="pb-6">
          LIIN’s flagship programs served as a launchpad for numerous social
          enterprises across sectors such as agriculture, renewable energy,
          education, and healthcare. These enterprises not only addressed
          critical societal challenges but also created sustainable livelihoods,
          contributing to Sri Lanka’s broader economic development goals.
        </p>
        <p className="font-semibold">
          Driving Policy Change- Establishing the National Advisory Board
        </p>
        <p className="">
          As the impact investing landscape in Sri Lanka matured, LIIN
          recognized the need for a more structured and strategic approach to
          scale its efforts. This led to a significant milestone in 2022—the
          establishment of the National Advisory Board (NAB) for Impact
          Investing in Sri Lanka.
        </p>
        <p className="pb-6">
          The NAB was formed as a multi-stakeholder body comprising government
          representatives, private sector leaders, impact investors, and civil
          society organizations. Its mandate was clear: to promote impact
          investing as a key lever for sustainable development in Sri Lanka. The
          NAB serves as a strategic advisory platform, influencing policy,
          advocating for regulatory reforms, and mobilizing resources to drive
          the growth of the impact investing ecosystem.
        </p>
        <p className="font-semibold">
          The On Eagle's Wings: Advancing Impact Through Entrepreneurship and
          Investment
        </p>
        <p className="pb-6">
          Lanka Impact Investing Network (LIIN), in partnership with USAID,
          unveiled the groundbreaking “On Eagle’s Wings” TV reality show at its
          launch event, marking the next phase of the Athpaura program. This
          32-episode initiative aims to empower 2,000 entrepreneurs, channel
          winners toward impact investment, and unlock $3.5M in funding. Focused
          on climate adaptation and gender-equitable SMEs, it targets nurturing
          400+ impact enterprises, driving inclusive, innovative, and resilient
          economic growth in Sri Lanka.
        </p>
        <p className="font-semibold">
          Looking Ahead- Scaling Impact for a Sustainable Future
        </p>
        <p className="">
          Today, LIIN stands as a testament to what visionary leadership and
          collective action can achieve. From laying the groundwork for impact
          investing in Sri Lanka to shaping national policy, LIIN’s journey has
          been one of resilience, innovation, and impact.
        </p>
        <p className="pb-6">
          The establishment of the NAB marks the beginning of a new chapter. As
          Sri Lanka grapples with complex socio-economic challenges, the impact
          investing ecosystem—powered by initiatives like LIIN—offers a pathway
          to sustainable and inclusive growth. By fostering collaboration
          between public and private sectors, mobilizing catalytic capital, and
          supporting high-impact ventures, LIIN continues to lead the charge in
          shaping a more equitable and sustainable future for all Sri Lankans.
        </p>
        <p className="font-semibold">Conclusion</p>
        <p className="">
          LIIN’s story is one of hope and determination. It underscores the
          transformative power of impact investing and the critical role of
          local champions in driving change. As Sri Lanka positions itself as a
          regional leader in impact investing, LIIN’s journey serves as an
          inspiration for countries around the world seeking to harness the
          power of capital for good.
        </p>

        <div className="mb-8">
          <img src={news171_people} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Chandula Abeywickrema</p>
            <p>Chairman</p>
            <p>LIIN (Lanka Impact Investing Network)</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
