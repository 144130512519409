import React from "react";
import { Link } from "react-router-dom";
import news171 from "../../../../assets/images/news178.png";
import news171_people from "../../../../assets/images/news178_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function BridgeIntoBdDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news171}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The roots of one of modern Bangladesh’s oldest educational NGOs goes
          back to World War II, where a group of soldiers from the region bumped
          into Fred Hyde, an Australian soldier off duty in dark alleyway in
          Jerusalem late one night. They happened upon the young soldier as he
          was being attacked by a gang of street robbers. Mr. Hyde credited the
          soldiers from imperial India for his life, and was so grateful, he
          promised to visit their country after the war was over. It took him a
          while, and but in the late 1970s, after retiring early as a small
          engine mechanic in outback Queensland, he made good on his promise,
          spending much of the rest of his long life—he died at the age of
          96—giving back to Bangladesh for that good deed done in the Jerusalem
          darkness.
        </p>

        <p className="pb-6">
          What makes the NGO he established, Co-operation in Development (CO-ID,
          aka Fred Hyde Schools) truly remarkable is not just the back story,
          but the story of the literally hundreds of other Australians that form
          its background.
        </p>
        <p className="pb-6">
          CO-ID builds schools in marginal country, often populated by either
          transient agricultural workers and their families, or landless folk
          dispossessed by erosion from their original homes. Back in Australia,
          young students from generally wealthy backgrounds—certainly relative
          to Bhola—hold fundraising events raising money one coin at a time, to
          send to their cousins in Bangladesh. Some schools’ children raise
          enough to pay for the operation of two or three whole schools in
          District Bhola.
        </p>

        <p className="pb-6">
          COID now runs 58 primary schools and kindergartens in the char
          districts of southern Bhola, Bangladesh, a region subject to daily
          tidal incursions, and exposed to seasonal peril from cyclones and
          storms in the Bay of Bengal. The region was described by the BBC as
          “ground zero of climate change”, due to the large population living
          barely a metre above high tide.
        </p>
        <p className="pb-6">
          The children’s fundraising is super-powered by two factors: the
          strength of the Aussie dollar, and a philosophy where all key
          managerial functions in Australia, and many of the key operations in
          Bangladesh, are run by Australian volunteers.
        </p>

        <p className="">
          In particular, whenever a new school has to be built or major repairs
          are required, Australian farmer, Paddy O’Leary, aged 73, himself a
          Queensland retiree, packs up his bags and heads to Bhola for months on
          end, to supervise work. He’s on hand to check quality, pay the bills,
          and sharpen negotiations with local suppliers. The organisation’s head
          of country, Olav Muurlink, holds down a day job as professor at an
          Australian university, and spends his holidays in Bangladesh, bouncing
          around on the back of motorbikes along mud tracks to the schools to
          meet with staff, and dodging traffic in Dhaka to cut through the red
          tape challenges of modern Bangladesh. Both pay their own way, in time
          and travel costs, which adds magical leverage to the ‘investments’
          made by young donors in Australia. Because the donors are only paying
          directly for ‘impact’, not overheads, young donors in Australia get an
          extraordinary return on investment—a single dollar coin buys a week of
          full-time full curriculum education for a child in Bhola.
        </p>
        <p className="pb-6">
          “We do see our role as more than just books, pens and teachers,
          however,” Dr. Muurlink says. “When children are injured in
          all-too-common traffic accidents, we step in and help the family’s get
          treatment.” As a researcher he’s developed projects on rural and
          remote health to ‘buy’ himself more time in Bangladesh for his NGO
          work, and sees the same kind of challenges in delivering good quality
          health (and education) in rural and remote areas in both Australia and
          Bangladesh. “We’ve been developing health clinics that offer remote
          access to specialists, but distance is still a major hurdle to
          overcome in emergencies.” The ‘tyranny of distance’ in Australia may
          be related to people children living in the desert, thousands of
          kilometres from schools, but in the delta region, even small distances
          can make life impossible. “We’ve built more than one wooden bridge
          (‘shaako’) over fast-moving streams to cut the distance to schools, or
          stop children from trying to get to school on rickety bamboo
          structures,” Dr. Muurlink says, adding that the NGO has seen too many
          child drownings as a result of poor transport infrastructure.
        </p>

        <p className="pb-6">
          “We have ambitions to go beyond just quantity in education,” he adds.
          The charity has a daily enrollment of over 7,000 children, but for Dr.
          Muurlink the challenge is quality. “We are focusing heavily on
          upskilling our teachers this year, to try and ensure that the children
          have the foundation to enter modern society and do well for their
          families and themselves.”
        </p>

        <div className="mb-8">
          <img src={news171_people} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Dr. Olav Muurlink</p>
            <p>Professor, Sustainable Innovation</p>
            <p>School of Business and Law,</p>
            <p>CQUniversity, Australia</p>
            <p className="mt-4">Program Leader, Future Leaders Program, Food Waste CRC</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
