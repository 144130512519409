import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { useFirestore } from "../../../hooks/useFirestore";

export default function FormComponents() {
  const initialObj = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };
  const [contactObj, setContactObj] = useState({ ...initialObj });
  const [success, setSuccess] = useState("");

  const { addDocument, response } = useFirestore("contactForm");

  useEffect(() => {
    if (response.success) {
      setContactObj({ ...initialObj });
      setSuccess("Form Submitted Successfully");
    }
  }, [response.success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess("");
    addDocument(contactObj);
  };
  return (
    <div className="bg-white pb-[300px]">
      <div className="py-20 py-40  bg-primary max-h-[1400px] md:max-h-[800px] px-6">
        <div className="text-center text-white ">
          <h1 className="text-5xl font-bold pb-8">Contact Us</h1>
          <p className="text-4xl pb-20">
            Email us for any queries or <br />
            clarifications
          </p>
        </div>

        <div className="max-w-5xl mx-auto ">
          <form
            className="bg-white grid grid-cols-1 md:grid-cols-3 rounded-md overflow-hidden shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="md:col-span-2 p-12">
              <h1 className="text-4xl font-bold pb-6">Send us a Message</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border border-gray-300 p-4 focus:outline-none placeholder:text-black"
                  required
                  value={contactObj?.firstName}
                  onChange={(e) =>
                    setContactObj({ ...contactObj, firstName: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border border-gray-300 p-4 focus:outline-none placeholder:text-black"
                  required
                  value={contactObj?.lastName}
                  onChange={(e) =>
                    setContactObj({ ...contactObj, lastName: e.target.value })
                  }
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  className="border border-gray-300 p-4 focus:outline-none placeholder:text-black"
                  required
                  value={contactObj?.email}
                  onChange={(e) =>
                    setContactObj({ ...contactObj, email: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="border border-gray-300 p-4 focus:outline-none placeholder:text-black"
                  required
                  value={contactObj?.phoneNumber}
                  onChange={(e) =>
                    setContactObj({
                      ...contactObj,
                      phoneNumber: e.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  placeholder="Subject"
                  className="md:col-span-2 border border-gray-300 p-4 focus:outline-none placeholder:text-black"
                  required
                  value={contactObj?.subject}
                  onChange={(e) =>
                    setContactObj({ ...contactObj, subject: e.target.value })
                  }
                />
                <textarea
                  type="text"
                  placeholder="Message"
                  className="md:col-span-2 row-span-2 border border-gray-300 p-4 focus:outline-none placeholder:text-black h-[150px] resize-none"
                  required
                  value={contactObj?.message}
                  onChange={(e) =>
                    setContactObj({ ...contactObj, message: e.target.value })
                  }
                />
              </div>
              {success && <p className="text-primary font-bold mt-3">{success}</p>}
              <div className="text-right pt-6">
                <button className="uppercase bg-primary px-8 py-4 rounded-full text-white font-bold">
                  Send Message
                </button>
              </div>
            </div>
            <div className="md:col-span-1 p-12 bg-[#F3DACA]">
              <h1 className="text-4xl font-bold pb-4">Info</h1>
              <p className="pb-4">
                260/B Evergreen Plaza (First Floor), Tejgoan Industrial Area,
                Dhaka-1208, Bangladesh.
              </p>
              <p className="pb-4">Email:</p>
              <p className="pb-4 text-primary">info@nabbangladesh.org</p>
              <div className="pt-4 grid grid-cols-2">
                <div className="flex items-center gap-4">
                  <a
                    href="https://www.facebook.com/nabbangladesh/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook className="h-6 w-6" />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/nab-bangladesh/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin className="h-6 w-6" />
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
