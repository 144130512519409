import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import FourYearReflectionDetails from "./components/FourYearReflectionDetails";

export default function FourYearReflection() {
  return (
    <Layout>
      <NewsHeader>
        Building Bangladesh’s Impact Investing Ecosystem: A Four-Years’
        Reflection November 2024
      </NewsHeader>
      <FourYearReflectionDetails />
    </Layout>
  );
}
