import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ImpactInvestmentSrilankaDetails from "./components/ImpactInvestmentSrilankaDetails";

export default function ImpactInvestmentSrilanka() {
  return (
    <Layout>
      <NewsHeader>
        The Journey of LIIN’s Impact Investment Path in Sri Lanka
      </NewsHeader>
      <ImpactInvestmentSrilankaDetails />
    </Layout>
  );
}
