import React from "react";
import { Link } from "react-router-dom";
import news171 from "../../../../assets/images/news171.jpg";
import news171_people from "../../../../assets/images/news171_people.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function InvestingInBetterFutureDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news171}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Impact investing revolves around driving measurable social and
          environmental benefits alongside financial returns. This approach has
          seen remarkable growth, with the market expanding from USD 502 billion
          in 2018 to USD 1 trillion in 2024, underscoring its increasing
          importance in addressing global challenges. The stakes are high:
          achieving the Sustainable Development Goals (SDGs) by 2030 requires
          closing a massive financing gap of USD 2.5 trillion globally, with
          Bangladesh alone needing USD 928 billion. In this context, impact
          investments have become a powerful tool for driving progress where
          it’s needed most.
        </p>
        <p className="pb-6">
          For decades, SAJIDA Foundation has been a driving force for
          transformative change in Bangladesh. Known for its pioneering work in
          healthcare, financial inclusion, and community resilience, the
          organization has consistently championed sustainable solutions
          empowering communities, catalyzing entrepreneurship, building equity
          and establishing. SAJIDA’s operation in Bangladesh has served over 6
          million individuals through focused development programs, financial
          services and specialized healthcare enterprises. SAJIDA has
          established and operates 6 wholly-owned social enterprises which
          include in-patient and out-patient mental health clinics, a leading at
          home care enterprise, a specialized learning hub for children and
          adolescents with learning disabilities, a hospital and a technology
          companies specialized in developing health tech and project management
          solutions. Through SAJIDA’s support, these enterprises have been able
          to develop successful services, attain cash flow positive operations
          and a few are scaling operations to localities beyond Bangladesh’s
          capital.
        </p>
        <p className="pb-6">
          <p>The Launch of ‘Impact Partners’</p>
          In order to expand on our support and address the financing gap in the
          market, we launched SAJIDA’s Impact Partners in 2021, an impact-first
          entrepreneurship development platform with the goal to catalyze
          impact, trigger innovation, and bring sustainable solutions to
          underserved markets through entrepreneurship building, provision of
          patient catalytic capital, and investment readiness training for
          impact-oriented early stage companies in Bangladesh. With the goal of
          positioning Bangladesh as a global market leader in impact
          entrepreneurship, SAJIDA’s Impact Partners aims to build equitable,
          investment-ready entrepreneurs and companies to catalyze impact,
          trigger innovation, and bring sustainable solutions to underserved
          markets through three key areas: entrepreneurship development,
          investment readiness training, and catalytic capital. SAJIDA’s Impact
          Partners is dedicated to empowering early-stage companies to create
          lasting social and environmental change.
        </p>
        <p className="pb-6">
          Addressing Challenges in Bangladesh’s Innovation & Entrepreneurship
          Ecosystem Bangladesh’s ecosystem, with its 7.8 million SMEs and over
          500 active startups, has demonstrated resilience and growth, raising
          USD 72 million in 2023 alone. Yet, early-stage companies continue to
          face significant challenges, from scaling operations and sustaining
          market reach. SAJIDA’s Impact Partners bridges this gap by catalyzing
          innovation and driving sustainable solutions for entrepreneurs
          navigating the “valley of death.”
        </p>
        <p className="pb-6">
          <p>Empowering Entrepreneurs for Sustainable Growth</p>
          <p>
            SAJIDA is committed to empowering entrepreneurs in Bangladesh
            through a range of impactful programs:
          </p>
          <p>
            Climate Innovation Fund: This initiative identifies and supports
            innovative local solutions aimed at enhancing resilience to climate
            change. It focuses on critical areas such as agriculture, food
            security, water security, and livelihoods, fostering sustainable
            development in vulnerable communities.
          </p>
          <p>
            B/deshi Catalyst: As an implementing partner of this accelerator and
            mentorship program, we connect Bangladeshi diaspora resources with
            local founders. This initiative helps entrepreneurs unlock their
            potential, scale their businesses, and achieve new growth
            milestones. Biniyog Briddhi Programme: As a certified service
            provider and trainer, we are dedicated to accelerating the growth of
            local impact enterprises. We provide specialized training in Impact
            Measurement & Management (IMM) and Investment Readiness & Raising
            (IRR), preparing businesses to secure investments of up to USD
            250,000 in funding.
          </p>
          To further support the growth of high-potential enterprises, SAJIDA
          has secured a fund of €2.2 million for the Orange Corners Innovation
          Fund under Orange Corners Access to Finance Programme. Launched in
          October this year, this financing facility will supplement the
          existing Orange Corners Incubation and Acceleration programme (OCIAC).
          Over the next five years, SAJIDA and Orange Corners Bangladesh will
          offer grants and soft loans of up to €50,000 to entrepreneurs,
          offering the capital they need to scale their businesses—particularly
          those deemed too high-risk by traditional funding sources.
        </p>
        <p className="pb-6">
          <p>Driving Impactful Growth through Strategic Fund Management</p>
          SAJIDA Impact Partners manages two funds under the Orange Corners
          Programme, an initiative of the Ministry of Foreign Affairs of the
          Netherlands. The OCIAC provides early-stage entrepreneurs with €5,000
          in grants, along with mentorship and networking opportunities, while
          the Orange Corners Innovation Fund (OCIF), which offers up to €45,000
          in soft loans to both OCIAC participants and other promising ventures
          in Bangladesh. By managing these funds, SAJIDA helps enable
          entrepreneurs to scale their businesses, create jobs, and drive social
          impact, contributing to a more inclusive and sustainable
          entrepreneurial ecosystem in Bangladesh.
        </p>
      
        <div className="mb-8">
          <img src={news171_people} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Muhymin Chowdhury</p>
            <p>Director – Impact Investment and Partnerships</p>
            <p>SAJIDA Foundation</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
