import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ImpactInvestmentStoryDetails from "./components/ImpactInvestmentStoryDetails";

export default function ImpactInvestmentStory() {
  return (
    <Layout>
      <NewsHeader>Impact Investment in Bangladesh: A New Story</NewsHeader>
      <ImpactInvestmentStoryDetails />
    </Layout>
  );
}
