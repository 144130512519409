import React from "react";
import events3 from "../../../assets/images/event1.jpg";
import events1 from "../../../assets/images/eventsImage1.png";
import events2 from "../../../assets/images/eventsImage2.jpeg";
import events4 from "../../../assets/images/InveSTART.png";
import event2024 from "../../../assets/images/event2024.jpg";
import { Button } from "../../../shared/ui/Button";
import { FaLocationArrow, FaSearchLocation } from "react-icons/fa";

export default function AllEvents() {
  let event1 = eventsData[1];
  let event2 = eventsData[2];
  let event3 = eventsData[3];
  let event4 = eventsData[0];
  return (
    <div className="max-w-5xl mx-auto pt-20">
      <div className="grid grid-cols-1 md:grid-cols-2 mb-20">
        <div className="text-center text-xl border-r border-dotted  border-black">
          <p>
            <span className="border-t border-primary">{event4?.year}</span>
          </p>
        </div>
        <div className="px-8">
          <h1 className="font-semibold text-3xl pb-4">{event4?.title}</h1>
          <img src={event4?.image} alt={event4?.title} />
          <div className="flex items-center gap-4 py-4">
            <FaLocationArrow className="fill-primary" />
            <div>
              <p className="text-xl">{event4?.eventDetails?.platform}</p>
              <p>{event4?.eventDetails?.date}</p>
            </div>
          </div>
          <div className="pb-2">
            <p className="pb-1">{event4?.description1}</p>
            <p>{event4?.description2}</p>
          </div>
          {/* <Button>Know More</Button> */}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mb-20">
        <div className="px-8">
          <h1 className="font-semibold text-3xl pb-4">{event1?.title}</h1>
          <img src={event1?.image} alt={event1?.title} />
          <div className="flex items-center gap-4 py-4">
            <FaLocationArrow className="fill-primary" />
            <div>
              <p className="text-xl">{event1?.eventDetails?.platform}</p>
              <p>{event1?.eventDetails?.date}</p>
            </div>
          </div>
          <div className="pb-2">
            <p className="pb-1">{event1?.description1}</p>
            <p>{event1?.description2}</p>
          </div>
        </div>
        <div className="text-center text-xl border-l border-dotted  border-black">
          <p>
            <span className="border-t border-primary">{event1?.year}</span>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mb-20">
        <div className="text-center text-xl border-r border-dotted  border-black">
          <p>
            <span className="border-t border-primary">{event2?.year}</span>
          </p>
        </div>
        <div className="px-8">
          <h1 className="font-semibold text-3xl pb-4">{event2?.title}</h1>
          <img src={event2?.image} alt={event2?.title} />
          <div className="flex items-center gap-4 py-4">
            <FaLocationArrow className="fill-primary" />
            <div>
              <p className="text-xl">{event2?.eventDetails?.platform}</p>
              <p>{event2?.eventDetails?.date}</p>
            </div>
          </div>
          <div className="pb-2">
            <p className="pb-1">{event2?.description1}</p>
            <p>{event2?.description2}</p>
          </div>
          {/* <Button>Know More</Button> */}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="px-8">
          <h1 className="font-semibold text-3xl pb-4">{event3?.title}</h1>
          <img src={event3?.image} alt={event3?.title} />
          <div className="flex items-center gap-4 py-4">
            <FaLocationArrow className="fill-primary" />
            <div>
              <p className="text-xl">{event3?.eventDetails?.platform}</p>
              <p>{event3?.eventDetails?.date}</p>
            </div>
          </div>
          <div className="pb-2">
            <p className="pb-1">{event3?.description1}</p>
            <p>{event3?.description2}</p>
          </div>
          {/* <Button>Know More</Button> */}
        </div>
        <div className="text-center text-xl border-l border-dotted  border-black">
          <p>
            <span className="border-t border-primary">{event3?.year}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const eventsData = [
  {
    id: "05",
    title: "South Asia Convening 2024",
    eventDetails: {
      platform: "Virtual Event",
      date: "3th - 5th December 2024",
    },
    image: event2024,
    description1: `NAB Bangladesh co-hosted, the ANDE South Asia Convening 2024, an initiative to
bring this ecosystem of entrepreneur support organisations in South Asia
together, initiating discussion to promote impact investment and catalyse cross-
border collaboration to further economic growth through entrepreneurship and
strengthen the small and growing businesses (SGBs) ecosystem.
`,
    description2: `This 3-day virtual Convening builds on the theme “Big Ideas for Small Business.”
with key discussions focusing on Regional Ecosystem Building for SGBs, examining
regional cooperation, infrastructure development, and digital transformation
driving economic growth, reducing poverty, and improving market access;
Population-Weighted Asset Under Management (AUM), exploring its definition,
scope, SWOT analysis, methods, and regional comparisons; and Impact
InvesTREND day, highlighting role of impact investing for sustainable
development in Bangladesh, covering regulatory frameworks, transparency, and
investment tools. Over the three days of 3 to 5 December 2024, the event covered
8 sessions with an august gathering of 269 participants from over 32 countries
becoming a platform for inspiration, connection, and action to unlock the
economic potential of South Asia`,
    year: "2024",
  },
  {
    id: "04",
    title: "Impact InveSTART",
    eventDetails: {
      platform: "BIDA Auditorium",
      date: "11th November 2023",
    },
    image: events4,
    description1:
      "The NAB Bangladesh organized the Impact InveSTART – an International Summit commemorating the 5th anniversary of NAB Bangladesh. The event signified the dawn of a new era for mainstreaming Impact Economy in Bangladesh. During this event, NAB declared November 10, 2023, as the National Impact Investment Day for Bangladesh, echoing the public launch of impact investment by the late Abul Maal Abdul Muhit, former Finance Minister, on November 10, 2016.",
    description2:
      "Hosted in the multipurpose hall of the Bangladesh Securities and Exchange Commission (BIDA) on November 11th  2023, the event attracted over 200 participants, including investment experts and impact entrepreneurs. Together, they navigated the path to position Bangladesh as the key investment destination for the 21st century and beyond. The Impact InveSTART not only commemorates historical milestones but also propels the momentum of BIISAP implementation.",
    year: "2023",
  },
  {
    id: "01",
    title: "Impact InveSTAR",
    eventDetails: {
      platform: "BIDA Auditorium",
      date: "3 July 2023",
    },
    image: events3,
    description1:
      "The Impact InveSTAR event was held in Bangladesh to acknowledge and appreciate the organizations and individuals who have contributed to the Impact Investment Ecosystem in the country. The CEO of the Global Steering Group for Impact Investment, Cliff Prior, delivered a keynote speech, praising the Bangladesh NAB for their leadership in the movement and their National Strategy and Action Plan. He emphasized the importance of collaboration among different sectors and offered support from the GSG.",
    description2:
      "The event was hosted in the premises of Bangladesh Investment Development Authority (BIDA) and saw participation from the likes of government, regulators, private sectors, national and international development partners, NGOs, peak business bodies and many more. The event concluded with remarks from the NAB Trust Chair and a traditional Bangladeshi dinner. ",
    year: "2023",
  },
  {
    id: "02",
    title: "Impact InveStory",
    eventDetails: {
      platform: "BIDA Auditorium",
      date: "02 August 2022",
    },
    image: events2,
    description1:
      "The NAB Bangladesh organized the ‘Impact InveStory’ – a day long conference to launch the Bangladesh Impact Investment Strategy and Action Plan (BIISAP) and highlight some of the key implementation plan identified and recommended in BIISAP. It was also an event to share the impact investment stories from across the globe and from local champions.",
    description2:
      "The event saw participation from government, regulators, private sectors, national and international development partners, NGOs, peak business bodies and many more. The event highlighted the passion and commitment of the different key stakeholders in creating a conducive and thriving environment for impact investment to grow in Bangladesh.",
    year: "2022",
  },
  {
    id: "03",
    title: "GSG Global Impact Summit 2021",
    eventDetails: {
      platform: "Virtual Event",
      date: "6th - 8th October 2021",
    },
    image: events1,
    description1:
      "The Global Impact Summit is the GSG’s annual convening of stakeholders from across the imact investment ecosystem. The summit will be an action focused event in the cloud that will facilitate virtual meetings and networking roundtables with representatives from governments, investors, mulitalerals, philanthropists, entrepreneurs and business leaders across the world.",
    description2:
      "For the last five years this 3-day event has been the leading forum for the impact community to come together, ecchange ideas, network and co-create the future of impact investment.",
    year: "2021",
  },
];
