import React from "react";
import { Link } from "react-router-dom";
import news172 from "../../../../assets/images/news172.jpg";
import news172People from "../../../../assets/images/trust1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function InvestmentMyJourneyDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news172}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Because the readership of this newsletter is expected to be familiar
          with the concept of Impact Investing, I prefer not to delve into its
          definition and narrative in the interest of space affordability.
        </p>
        <p className="pb-6">
          In Bangladesh, impact investment shed its light fairly early, and also
          having a clear point of beginning marked by the launch of the first
          international Impact Investment Summit in November 2016 at Dhaka,
          hosted by Build Bangladesh, the impact enterprise of the Impress
          Group. The Summit was inaugurated by the Hon’ble former Finance
          Minister in the presence of Governor of Bangladesh Bank, Chairman,
          Securities and Exchange Commission, potential private investors, and
          representations from 8-10 mostly western countries, practicing impact
          investment. It happened within years of resolve by the Rockefeller
          Foundation and G-7’s initiative for promoting impact investment as a
          new and additional investment regime. Sir Ronald Cohen, tasked by G-7
          Group to promote impact investment, unrolled Global Steering Group
          (GSG) for Impact Investment, as a vehicle for support and propagation
          of the concept of impact investment in developing and emerging
          economies also. GSG required formation of country chapters for impact
          investment: a National Advisory Board (NAB), which would recommend the
          country concerned for its inclusion in GSG.
        </p>
        <p className="pb-6">
          Listed below are some of our activities that have shaped up impact
          investment as a movement in pursuit of developing an eco-system that
          will promote and advance impact investing. Nov 2016- first Impact
          Investment Summit; March 2017- first Impact Investor Workshop in
          Bangladesh; Nov 2017- the launch of $100 million Build Bangladesh-UNDP
          impact investment at the United Nations in New York; Nov 2018— secured
          membership no 19 in GSG on Impact Investment and before that
          establishment of National Advisory Board on Impact Investment with
          Economic Relations Division (ERD) Secretary of the Government as the
          Chair. The formation of a National Advisory Board on Impact Investment
          in 2018 was a turning point. Headed by the Secretary ERD, drawing
          membership from the regulators, investment promotion agency, financial
          sector, academia, multilateral development partners, diplomatic
          mission, NGOs, private partners, NAB emerged as a robust organization
          promoting impact investment in Bangladesh.
        </p>
        <p className="pb-6">
          The two studies, tasked by NAB Bangladesh (a) Policy Landscape
          Analysis: Impact Investment in Bangladesh (2020) and (b) Bangladesh
          Impact Investment Strategy and Action Plan (BIISAP in2022) were
          critically important in unraveling our work. The first aimed at
          benchmarking impact investment in Bangladesh and the second,
          identified 9 key interventions, which were discussed at length and
          resolved to pursue implementation in a 4-day seminar of all
          stakeholders held at Dhaka.
        </p>
        <p className="pb-6">
          In November 2023, the Securities and Exchange Commission (BSEC) and
          Build Bangladesh jointly organized Impact Investment awareness
          building workshop held at BSEC. A seminar, called ‘Impact InveSTART’
          marking the 5th year of NAB Bangladesh, was organized at BIDA. Prior
          to this, in July 2023, NAB organized ‘Impact InveSTAR’, with Cliff
          Prior, than CEO GSG, and some regional countries attending to
          recognize the active players and achievers on the impact investment
          arena in Bangladesh.
        </p>
        <p className="pb-6">
          Successes were also achieved when the Bangladesh Bank gave parity to
          Impact Investment with their Green Financing support initiative.
          Directives were issued from central bank to all commercial banks to
          set aside 5 percent of their portfolios for Green and Impact
          Investment. The Finance Ministry scaled down Stamp Duty required
          against overseas investment to a very affordable limit, proactively
          promoting foreign investment. The Capital Gain Tax was also exempted
          for impact investment.
        </p>
        <p className="pb-6">
          I confess that taking up a well-run and accepted western concept and
          making it work in an emerging market setting can be quite challenging.
          Impact investment prioritizes securing measurable social and
          environmental impact and less on profit. Altruistic as its definition
          suggests, Impact Investment in these countries, somewhat basks under
          generous investment flowing from their governments, big family funds
          and foundations. They all offer a fertile seeding ground. The scenario
          in countries like Bangladesh is different because of the absence of
          big capital, big family funds and high net worth individuals, who are
          not prepared to give precedence to positive and social impact over
          profit.
        </p>
        <p className="pb-6">
          Despite formidable headwinds, the Build Bangladesh platform, with
          support and guidance from NAB and its charter, continue to strive to
          hit the global highroads of Impact Investment that would herald a new
          and supportive finance regime in Bangladesh.
        </p>
        <div className="mb-8">
          <img src={news172People} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Arastoo Khan</p>
            <p>Managing Director and CEO, Impress Capital Limited (ICL)</p>
            <p>Chair, NAB Trust</p>
            <p>Member Secretary, NABG</p>
            <p> Chair, Advisory Board, Build Bangladesh</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
