import React from "react";
import nabbanimg1 from "../../../assets/images/nabbanimg1.png";
import nabbanimg2 from "../../../assets/images/nabbanimg2.png";
import nabbanimg3 from "../../../assets/images/nabbanimg3.png";
import nabbanimg4 from "../../../assets/images/nabbanimg4.png";
import nabbanimg5 from "../../../assets/images/nabbanimg5.png";
import { Link } from "react-router-dom";

export default function NavBangladeshMission() {
  return (
    <div className="container mx-auto py-20">
      <div className="max-w-7xl mx-auto px-8 md:px-16 text-center ">
        <p className="px-8 md:px-16">
          The National Advisory Board (NAB) acts as the advisory committee to
          guide the development of Impact Investment in Bangladesh. It is a
          member-based advisory board recognised as one of the GSG National
          Partners forming the GSG Impact Partnership. Its objective is to help
          build the impact investment ecosystem in Bangladesh while
          complementing the mainstreaming investment with impact investment
          enabling good for the people of Bangladesh. The NAB is supported with
          secretariat functions in Build Bangladesh ({" "}
          <span className="font-bold text-primary">
            www.buildbangladesh.org.bd
          </span>{" "}
          ) a private sector pioneer empowering impact investment in Bangladesh.
          The NAB’s membership of the GSG Impact paved the way for Bangladesh
          securing much needed exposure in the global network of Impact
          Investment.
        </p>

        <h2 className="py-10 font-bold text-2xl md:text-3xl text-center">
          NAB Bangladesh’s mission is to:
        </h2>

        {/* Grid tree */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary flex flex-col  items-center pb-10">
            <div className="text-center pb-10">
              <img src={nabbanimg1} className="w-20 h-20 text-center" />
            </div>
            <p>
              Raise awareness on Impact Investment across a broad range of
              constituents to achieve inclusive growth
            </p>
          </div>
          <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
            <div className="text-center pb-10">
              <img src={nabbanimg2} className="w-20 h-20 text-center" />
            </div>
            <p>
              Enable the policy and regulatory environment where Impact
              Investments can thrive
            </p>
          </div>
          <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
            <div className="text-center pb-10">
              <img src={nabbanimg3} className="w-20 h-20 text-center" />
            </div>
            <p>
              Create consensus on and broad-based adoption of Impact
              Measurements Standards and metrics to demonstrate social,
              environmental and financial returns
            </p>
          </div>
        </div>

        {/* Grid Two */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 py-20">
          <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary flex flex-col items-center pb-10">
            <div className="text-center pb-10">
              <img src={nabbanimg4} alt="" className="w-20 h-20 text-center" />
            </div>
            <p>
              Align Impact Investment to complement Government priorities and
              the SDGs
            </p>
          </div>
          <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
            <div className="text-center pb-10">
              <img src={nabbanimg5} alt="" className="w-20 h-20 text-center" />
            </div>
            <p>
              Build & promote adequate infrastructure and mechanisms to increase
              successful Impact Investment deals in social impact sectors such
              as education, healthcare, housing, sanitation, financial
              inclusion.
            </p>
          </div>
        </div>
        <p className="font-semibold max-w-3xl text-[16px] mx-auto">
          The National Advisory Board has member  from around 18
          organizations. To see the list of NAB Bangladesh Members,{" "}
          <Link to="/our-people">
            <span className="font-bold text-primary">Please Click Here</span>
          </Link>
        </p>
      </div>
    </div>
  );
}
