import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import BridgeIntoBdDetails from "./components/BridgeIntoBdDetails";

export default function BridgesInToBd() {
  return (
    <Layout>
      <NewsHeader>
      Building Bridges in—and to--Bangladesh
      </NewsHeader>
      <BridgeIntoBdDetails />
    </Layout>
  );
}
