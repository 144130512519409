import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import InvestmentMyJourneyDetails from "./components/InvestmentMyJourneyDetails";

export default function InvestmentMyJourney() {
  return (
    <Layout>
      <NewsHeader>Impact Investment in Bangladesh: My Journey</NewsHeader>
      <InvestmentMyJourneyDetails />
    </Layout>
  );
}
