import React from "react";
import { Link } from "react-router-dom";
import news173 from "../../../../assets/images/news173.jpg";
import news172People from "../../../../assets/images/farhad_reza_circle.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function NeverPromisedDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news173}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Almost 35 years ago, my wife and I left Bangladesh to settle in
          Australia. We went there with nothing but a small suitcase full of big
          dreams, hopes and aspirations along with the instinct for a better
          life, selfishly just for us!
        </p>
        <p className="pb-6">
          27 years later, I am back in Bangladesh with the ‘same suitcase’,
          still with full of dreams, hopes and aspirations. But this time the
          dreams, hopes and aspirations are selfless. They are focused to build
          Bangladesh by capitalising on the collective strengths of all
          Bangladeshis and our friends across the globe by challenging our
          limits rather than limiting our challenges!
        </p>
        <p className="pb-6">
          This is the beginning of my Impact InvesTOUR, not an easy journey
          though! Our country fought colonialism, war of independence, famine,
          disaster, food and nutrition security, clothing, housing, education,
          health, agriculture, socio-economic independence – the overall
          wellbeing for the people in this region, human rights and freedom of
          speech for our own people!
        </p>
        <p className="pb-6">
          Bangladesh has been named as the fastest growing economy in the
          Asia-Pacific economies out of 32 countries according to the latest
          report published by the World Economic Forum (2023). Bangladesh has
          made enormous strides in the past 53 years – from one of the poorest
          nations at birth, it is now one of the fastest-growing economies. Over
          the recent years, Bangladesh has grown faster than few Asian countries
          and is now set to emerge as a trillion-dollar economy powered by an
          ambitious business community. The country's is expected to ascend to
          $1 trillion GDP by 2030 if it secures a growth rate of 10% or by 2040
          with an even slowed-down growth rate of 5%. It outpaced its major
          Asian peers including Vietnam, India, Philippines, Thailand, and
          Indonesia with an impressive 6.4% average growth over the 2016-21
          period. On a 10-year gross domestic product (GDP) compound annual
          growth rate (CAGR) basis, Bangladesh ranked as the fastest growing
          economy in the Asia-Pacific region and also the fastest in the
          frontier markets, Bangladesh's CAGR over the last 10, 5 and 3 years
          was 9.1%, 9.4% and 8.5% respectively.
        </p>
        <p className="pb-6">
          In relation to finance and investment, historically, our region
          started with a different ‘model’ of banking. The early banking system
          was introduced by the Afghan traders popularly known as Kabuliwalas.
          Muslim businessmen from Kabul, Afghanistan came to India and started
          money lending business in exchange of interest sometime in 1312 A.D.
          During the period of British rule, the merchants established the first
          modern bank headquartered in Dhaka, the Dacca Bank, established in
          1846. It was purchased by Bank of Bengal in 1862. Bank of Bengal
          opened branches in Sirajganj and Chittagong in 1873, and in Chandpur
          in 1900.
        </p>
        <p className="pb-6">
          People gradually started to save, women started to transfer their
          savings from non-secured steal trunk, pillow cover, bamboo pole inside
          the home to more formal arrangements through Banks. However, the data
          from the Bangladesh Bureau of Statistics (BBS) survey titled
          “Bangladesh Sample Vital Statistics” showed that nearly 72 percent of
          those aged over 15 did not avail of bank services in 2023. Only 28.3
          percent had an account with a bank or NBFI last year, it showed,
          increasing from 26.2 percent the year prior.
        </p>
        <p className="pb-6">
          Since the birth of Bangladesh as an independent country in 1971, its
          very first private sector bank was AB Bank Limited. This bank was
          incorporated on 31st December 1981 and started its effective operation
          from 12th April 1982.
        </p>
        <p className="pb-6">
          Globally, in relation to the financial/banking industry, the Jews in
          Jerusalem introduced a kind of banking in the form of money lending
          before the birth of Christ. The word ‘bank’ was probably derived from
          the word ‘bench‘, as during ancient time Jews used to do money lending
          business sitting on long benches. First modern banking was introduced
          in 1668 in Stockholm, which opened up a new era of banking activities
          throughout the European Mainland. The industrial revolution needed new
          financial instruments. New changes were introduced with the emergence
          of the gold standard, stock markets and insurance. First stock
          exchange was established in London (1698). Philadelphia Stock Exchange
          was one of the first stock brokerage houses. People started to invest
          in companies that are efficient and produce profits, leading to
          overall economic growth through competition.
        </p>
        <p className="pb-6">
          The World War required war-making states to mobilize and sustain the
          financial resources. What made war finance during the conflict so
          special is that this challenge had never been confronted in a world
          economy as large, deeply interconnected, and sophisticated as that
          which existed in 1914. Financial institutions gradually transformed
          over last 100 years into different streams in addition to the
          traditional debt finance to adapt micro finance, social business,
          private equity, venture capital, bonds, family office, trust, grants,
          charity, not-for-profit, and philanthropy. Before analysing this
          scenario further, let’s have a flash back on the previous and current
          financing/investment and delivery ecosystems in place.
        </p>

        <ul className="pl-20 pb-10">
          <li>1839: Anti-Slavery Society was established as the First NGO.</li>
          <li>
            1945: UN Chapter 10, Article 71 of the UN Charter first had
            ‘Non-Government Organisation’.
          </li>
          <li>
            1953: Social Entrepreneurship and social enterprise were used by H.
            Bower in his book Social
          </li>
        </ul>

        <p>Responsibilities and Business.</p>

        <ul className="pl-20 pb-10">
          <li> 1955: First Stock Market was established.</li>
          <li>
            1980-1990’s: It became popular through Bill Drayton, when he started
            his own social enterprise in 1980.
          </li>
          <li>1926: Public Service Commission was established in India.</li>
          <li>
            1971-72: Bangladesh Civil Service was established and
            administratively divided as Division, Districts,
          </li>
        </ul>
        <p>Upazila and Union.</p>

        <ul className="pl-20 pb-10">
          <li> 1972: BRAC started Social Entrepreneurship.</li>
          <li>
            1976: Established the Grameen Bank and launched the concept of Micro
            Finance and Social Business .
          </li>
          <li>
            {" "}
            1976: Stock Market resumed in Bangladesh after five years since
            1971.
          </li>
          <li>
            1983: Micro credit was provided by the Grameen Bank as the first
            one. Ireland had similar principles to lend money to the poor in the
            1700s.
          </li>
          <li>2010: Established Public Private Partnership Authority</li>
          <li>
            {" "}
            2015: Alternative Investment Rules for Private Equity, Venture
            Capital, and Impact Fund.
          </li>
          <li>
            2016: Establishment the Bangladesh Investment Development Authority
            (BIDA), the one stop shop as the investment promotion agency.
          </li>
          <li>
            2016: Establishment of the first Impact Enterprise in Bangladesh –
            Build Bangladesh under the Impress Group.
          </li>
          <li>
            2016-2017: Build Bangladesh launched the concept of impact
            investment publicly through the first international conference on
            impact investment in Bangladesh, followed by the first international
            workshop on impact investment in 2017.
          </li>
          <li>
            2018: Establishment of the National Advisory Board for impact
            investment in Bangladesh. Became the 19th member country of the
            Global Steering Group (GSG) Impacts.
          </li>
          <li>
            2020: Launched the Policy Landscape of Impact Investment in
            Bangladesh.
          </li>
          <li>
            2022: Launched the Bangladesh Impact Investment Strategy and Action
            Plan (BIISAP).
          </li>
          <li>
            2023: Established the seven members National Advisory Board (NAB)
            Trust for Impact Investment in Bangladesh.
          </li>
        </ul>

        <p className="pb-6">
          With all these ongoing evolutionary initiatives relevant to
          Bangladesh, the poverty rate in Bangladesh has been reduced from 41.5
          per cent in 2006 to 18.7 per cent in 2022, and extreme poverty has
          gone from 25.1 per cent to 5.6 per cent. However, still in Bangladesh,
          the proportion of employed population earn below $1.90, purchasing
          power parity/day in 2022 was 2.7%. For every 1,000 babies born in
          Bangladesh in 2021, 27 died before their 5th birthday.
        </p>
        <p className="pb-6">
          Over last few centuries, funding for positive impacts to society,
          economy, environment, and proper governance were delivered through
          public service, private sector, public-private partnership, NGO/INGO,
          social enterprise, social business, micro-credit,
          philanthropy/charity, debt finance. market demand and market failure
          allowed us to be innovative and invent more effective way to use our
          money to ensure a better life for generations to come. These changes
          of demand and opportunities to supply more efficient tools to face the
          21st century challenges inspired us to think outside the square.
        </p>
        <p className="pb-6">
          But the question now is, how COVID-19, the war in Europe and the
          middle east and finally the student-people uprising in 2024 will
          influence the ongoing challenges in 2025 and future socio-economic
          indicators, investments and eventually the overall financial ecosystem
          of Bangladesh. The impacts of these events are pushing us to rethink
          and re-evaluate our work, and what it might mean for us and the next
          generation investors/investees to design for a Bangladesh that will
          probably not be the same! What will be our new perception and goals
          when working with the existing financial instruments or designing new
          investment instruments to do well and do good at the same time?
        </p>
        <p className="pb-6">
          We believe, there has never been a greater challenge and opportunity
          for investors to be innovative and creative to complement social,
          economic, and environmental reforms while ensuring financial gain to
          win against the negative impacts of some of these ‘man-made’ disasters
          along with hopes for positive lights and opportunities. We have moved
          forward from the 19th century profit only mindset focus for
          investments, to 20th century risk and its mitigation plans, and
          finally in 21st century we have incorporated impacts in our mindset!
          Our InvesTOUR transformed our mindset from only profit, to profit,
          people, planet, participation, and prosperity centric investment –
          Impact Investment! We realised, people do not want charity, they want
          a chance.
        </p>
        <p className="pb-6">
          In 2007, Rockefeller Foundation introduced the concept of impact
          investment. We got familiar with ESG complying businesses, SDG since
          2015 along with the SDG financing gap. According to the 2017 SDG
          Financing Strategy, Bangladesh requires an additional $928 billion to
          implement the Sustainable Development Goals by 2030, with the private
          sector accounting for 42 per cent.
        </p>
        <p className="pb-6">
          Can impact investing complement us in this difficult journey? We
          believe it can.
        </p>
        <p className="pb-6">
          Bangladesh became part of the global movement of Impact Investment as
          the 19th member country of the Global Steering Group for Impact (GSG
          Impact) in 2018. Bangladesh Securities and Exchange Commission
          released the Alternative Investment Rule 2015 to securitise – private
          equity, venture capital and impact fund in 2015. Bangladesh Bank
          extended its support for impact investment - linking impact investment
          with green finance and also tax incentives announced by the National
          Board of Revenue are very encouraging for the ecosystem players.
          Private sector like the Impress Group with their impact investment
          stream Build Bangladesh came forward and organised the first
          international conference on impact investment in November 2016
          followed by the first international workshop on impact investment in
          2017.
        </p>
        <p className="pb-6">
          However, the leadership demanded a planned development of the
          ecosystem, required a structure, required an advisory board rather
          than running with series of projects with a badge of impact
          investment, resulting impact washing. Hence, the creation of the
          National Advisory Board (NAB) for Impact Investment in Bangladesh in
          2018. NAB Bangladesh, in partnership with the UN ESCAP, Embassy of
          Switzerland, British Council Dhaka and Build Bangladesh first produced
          the Policy Landscape Analysis for Impact Investment in Bangladesh in
          2020. Followed by the Bangladesh Impact Investment Strategy and Action
          Plan (BIISAP) in 2022, supported by the Embassy of Switzerland and
          Build Bangladesh.
        </p>
        <p className="pb-6">
          To ensure that the implementation plans are discussed thoroughly and
          actioned appropriately. Build Bangladesh and Light Castle Partners
          (LCP) jointly hosted 4 stakeholders workshop supported by the NAB
          Bangladesh, Embassy of Switzerland and BRAC in July 2022. These
          workshops included participants from the groups of investees,
          investors, intermediaries, regulators, and development partners. The
          National Strategy for Impact Investment is in place to direct us
          through our Impact InvesTOUR. This is the first strategy to guide the
          implementation of impact investment from a national perspective,
          developed through an inclusive consultative process. I must
          acknowledge the comprehensive works that has been undertaken by Light
          Castle Partners and Build Bangladesh to bring this document together
          has been exemplary.
        </p>
        <p className="pb-6">
          One of the recent milestones achieved to complement the BIISAP
          implementation plan, was to host Mr Cliff Prior, former CEO of the GSG
          as the keynote speaker at the Impact InveSTAR in Bangladesh in June
          2023. Impact InveSTAR was designed to acknowledge and appreciate works
          of the impact investment ecosystem builders, the ‘STARS’ shining on
          the impact investment sky over Bangladesh.
        </p>
        <p className="pb-6">
          In addition, the Bangladesh NAB, at the GSG Annual Summit in October
          2023, has presented the concept of Population Weighted Asset Under
          Management (AUM) to be considered along with the next tipping point of
          raising/allocating 10% of all AUM as impact investment. This was
          followed by the Impact InveSTART, as part of the Bangladesh Impact
          Investment Strategy and Action Plan (BIISAP) implementation. The one
          and half day long programme has been successfully implemented as an
          international impact investment summit on the first day and an
          interactive workshop with the private sector peak bodies and relevant
          stakeholders on the second day in partnership between NAB Bangladesh
          and the Bangladesh Securities and Exchange Commission (BSEC). The
          focus for both the days was to highlight the Impact Investment
          progress in Bangladesh and guide the way forward in line with key
          investment instruments such as Macro Investment , Capital Markets ,
          Outcome Based Finance , Social Impact Bond , Blended Finance ,
          Catalytic Funding , Micro Impact Investment etc.
        </p>
        <p className="pb-6">
          The discussion sessions were designed to promote opportunities in
          Bangladesh for sustainable investment in partnership with local and
          global stakeholders. The summit and the workshop also explored
          opportunities to influence untapped sources of funds to reinforce the
          local impact investment market. While impact investment stakeholders
          including investors are very optimistic for Bangladesh because of its
          people-power potential to grow, supportive regulatory framework, and
          younger demographic trends, there is still significant challenges that
          discourages investors from entering the local market. Some of these
          key challenges are:
        </p>
        <ul className="pl-20 pb-10">
          <li>
            Proper due diligence process and absence of local expertise to
            manage impact fund.
          </li>
          <li>
            Structure for investment, outcomes monitoring, measurements, and
            reporting of investment.
          </li>
          <li> Follow up of investments.</li>
          <li>
            Difficulty in raising funds with limited availability of local
            funds.
          </li>
          <li>Many investors are still setting up overseas fund.</li>
          <li>
            {" "}
            Fund repatriation difficulties and absence of effective exit
            strategy.
          </li>
        </ul>
        <p className="pb-6">
          The NAB Bangladesh is working on the way forward addressing these
          challenges through its ongoing consultations with local and global
          stakeholders to inspire the ongoing impact investment movement in
          Bangladesh while sharing lights on resolving these critical issues to
          ensure Bangladesh as the seeding fertile ground for impact investment.
          The growth of impact investment in such a supportive ecosystem
          including a new vision from 2024 is the best investment instrument to
          work as the ‘antidote’ against the possible ‘man-made’ negative
          impacts.
        </p>
        <p className="pb-6">
          According to the Global Impact Investment Network (GIIN), impact
          investing has attracted increasing interest among investors who are
          allocating more capital to impact investments year-over-year. It has
          identified the overall global investment is more than $250 trillion
          and projecting the prospects for capturing at least one percent of
          this investments, which is around $2.5 trillion, for global impact
          investment.
        </p>
        <p className="pb-6">
          With enabling ecosystem and ingredients, few private sectors have
          already delivered projects and programmes at the grass root level
          across Bangladesh, which revealed the effectiveness of impact
          investment as an effective tool. It is now time for reinforcing impact
          investments in Bangladesh through ongoing/new instruments like the
          Impact Entrepreneurs Incubator/Accelerator Programme (e.g ygap
          Bangladesh by Build Bangladesh), Outcomes Based Funding (e.g. Social
          Impact Bond and Development Impact Bond), Micro Impact Investment
          (MI2) (e.g. Shochhol by Build Bangladesh), Impact Based Open Ended
          Mutual Funds (e.g. ICL mutual funds), initiatives like the Needle
          Innovation Challenge by Build Bangladesh in partnership with the H&M
          Foundation, Binyog Briddhi by the Embassy of Switzerland and Orange
          Corner by the Embassy of Netherlands to prepare the next generation
          investors and investees with enough outcome focused power including
          the works done by the IFC, UNDP, JICA, BII, USAID, DFAT, ADB, and
          BRAC.
        </p>
        <p className="pb-6">
          Recently in partnership with the Aspen Network of Development
          Entrepreneurs (ANDE), the NAB Bangladesh hosted a day long virtual
          summit on Impact InvestTREND in December 2024. In addition to the key
          theme of ‘Mainstreaming Impact Investment’, the outcomes for that day
          included way forward directions on:
        </p>
        <ul className="pl-20 pb-10">
          <li>impact regulations, transparency, instruments and scaling up.</li>
          <li>
            considering the number of people, demographic cohorts, and the
            environment received benefits from impact investment movement.
          </li>
          <li>
            {" "}
            demonstrated impact accountabilities incorporating all stakeholders:
            investors, investees, intermediaries, and market builders.
          </li>
          <li>
            acknowledging positive contributions of the impact investment
            movement leaders.
          </li>
          <li>
            {" "}
            sharing local, regional and global learnings of impact investment
            outcomes.
          </li>
          <li>
            inspiring future investors, innovators, local and global partners.
          </li>
          <li>
            Considering looking through the Build Bangladesh “Star Lense”
            focusing inclusivity, gender equality, climate change, digital
            innovation, and intergenerational wellbeing.
          </li>
        </ul>

        <p className="pb-6">
          Impact investment is becoming an influential instrument to bring
          different mixtures of people, ideas, and resources to challenge
          difficult social and environmental issues while allowing positive
          solutions to achieve social and environmental good. Impact investment
          is now being used to finance initiatives such as aged care, health,
          housing, education, infrastructure, water and sanitation, financial
          inclusion, sustainable agriculture and development of the services
          sector. The National Advisory Board (NAB) for Impact Investment in
          Bangladesh will continue to deliver education/awareness programme
          targeting all Impact Investment ecosystem stakeholders.
        </p>
        <p className="pb-6">
          I strongly believe that impact investment is the best instrument to
          achieve long-term goals ensuring 21st century way forward for the
          people of Bangladesh.
        </p>

        <div className="mb-8">
          <img src={news172People} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Architect Farhad Reza</p>
            <p>President, Build Bangladesh</p>
            <p>
              CEO, National Advisory Board for Impact Investment in Bangladesh
            </p>
          </div>
        </div>

        <p>References and Footnotes:</p>
        <ul className="pl-20 pb-10">
          <li>
            The Trillion Dollar Prize – Local Champions Leading the Way,
            November 2022, Boston Consulting Group Md. Saiduzzaman Selim, “The
            banking system of Bangladesh - Prospects &Challenges” January, 2015
          </li>
          <li>
            https://prezi.com/kkbchdgzkwxr/new-financial-instruments-in-the-industrial-revolution/
          </li>
          <li>
            Social Business: maximise profit while maximising benefit to the
            society and the environment and the profit are fully used to fund
            social programme.
          </li>
          <li>
            Impact investing is an investment that seeks to generate financial
            returns while also creating a positive social or environmental
            impact.
          </li>
          <li>
            Macro investing or "global-macro investing" refers to investing
            based on global economic patterns, including but not limited to
            which reserve assets have performed and are predicted to perform the
            best in the face of economic chaos.
          </li>
          <li>
            Capital markets are financial markets that bring buyers and sellers
            together to trade stocks, bonds, currencies, and other financial
            assets.
          </li>
          <li>
            Outcome Based Finance, the money is invested through a service
            provider for the period of service provision while the outcome payer
            repays when the agreed-upon outcomes are delivered.
          </li>
          <li>
            Social impact bond (SIB) is a contract with the public sector or
            governing authority, whereby it pays for better social outcomes in
            certain areas and passes on part of the savings achieved to
            investors.
          </li>
          <li>
            Blended finance is the strategic use of development finance for the
            mobilisation of additional finance towards sustainable development
            in developing countries. It attracts commercial capital towards
            projects that contribute to sustainable development, while providing
            financial returns to investors. This innovative approach helps
            enlarge the total amount of resources available to developing
            countries, complementing their own investments and ODA inflows to
            fill their SDG financing gap, and support the implementation of the
            Paris Agreement.
          </li>
          <li>
            Catalytic funding is the provision of resources to an investment
            fund or vehicle designed to mitigate risk and improve the fund's
            overall viability to attract new investment for development goals.
          </li>
          <li>
            Micro Impact Investment is designed to empower recipients with small
            investment to transform investee to investors through ongoing
            engagement and literacy programme to ensure social and financial
            security.
          </li>
         
        </ul>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
