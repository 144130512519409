import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import MalaysiaImpactDetails from "./components/MalaysiaImpactDetails";

export default function MalaysiaImpact() {
  return (
    <Layout>
      <NewsHeader>
        The Malaysia Impact Alliance: Building an Ecosystem for Impact
        Investment in Malaysia
      </NewsHeader>
      <MalaysiaImpactDetails />
    </Layout>
  );
}
