import React from "react";
import { Link } from "react-router-dom";
import news171 from "../../../../assets/images/news179.png";
import news171_people from "../../../../assets/images/news179_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function FourYearReflectionDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news171}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          In 2020, Switzerland embarked on a journey into impact investing with
          the launch of the B- Briddhi programme, a pioneering effort to build
          Bangladesh's impact investing ecosystem. Reflecting on the past four
          years, it has been humbling and exciting to observe the local
          ecosystem mature, and most importantly, the tangible benefits such as
          reaching out to over 150’000 low-income beneficiaries by financing 15
          scalable impact enterprises, with the significant transformation of
          Bangladesh as a backdrop.
        </p>

        <p className="font-semibold">
          Bangladesh’s Potential for Impact Investing
        </p>

        <p className="pb-6">
          Bangladesh has been observing a dip in traditional donor funding,
          posing challenges but also creating opportunities for innovative
          financing options such as impact investing. Meanwhile, impact
          enterprises have proven themselves as powerful agents to address
          essential development needs, such as poverty reduction, healthcare,
          and education in a sustainable manner. To scale solutions for a
          population of over 165 million, especially for its most vulnerable
          populations, impact finance has been a great solution to address the
          financing gap.
        </p>
        <p className="pb-6">
          Switzerland, a longstanding champion of sustainable development, has
          played a key role in supporting these efforts in Bangladesh. The
          Embassy of Switzerland, through initiatives like B-Briddhi, has
          collaborated with local actors to promote impact enterprises,
          especially those addressing climate change and women's economic
          empowerment. We see private capital as crucial in advancing solutions
          for climate resilience, while gender equity — already notable in
          Bangladesh with a 37% participation rate of women in the workforce —
          has room to grow further through impact-driven enterprises. To date,
          we have mobilised over USD24 million as private capital through
          B-Briddhi's innovative financing instruments, crowding in more
          resources to address the most critical challenges of the country,
          demonstrating how blended finance could unlock resources for
          sustainable and inclusive growth.
        </p>

        <p className="font-semibold">What Have We Learnt?</p>
        <p className="pb-6">
          There are three fundamental building blocks that I perceive to be
          essential in supporting the growth of a credible and effective impact
          investing market. Namely linking financial rewards, (e.g., grants), to
          impact performance, collaborative approach between system actors,
          including the Government, and knowledge dissemination, also in
          international circles. B-Briddhi embeds all these three success
          factors across its four pillars of activities and represents the
          quintessential of effective impact investing.
        </p>
        <p className="pb-6">
          One of the most important lessons from B-Briddhi has been the central
          role of impact measurement and management (IMM) in elevating the
          credibility and performance of impact enterprises. As we are looking
          to create positive change in people’s lives through impact investing,
          impact measurement goes beyond outreach numbers and captures the
          improvement of life quality experienced by the customers. In all our
          transactions, impact metrics were carefully crafted so that the focus
          is on the effectiveness – are we doing the right things? - of
          solutions, rather than just quantity. By linking financial rewards to
          impact performance, impact enterprises are recognised for their
          value-creation, and therefore are incentivised to outperform and lead
          to better and/or faster outcome achievements. The practice of
          independent verification of impact data has also been critical to
          enhancing trust and credibility and encourages significant capital
          flows to impact-driven businesses, allowing them to scale and deepen
          their impact. This allows investors, both local and international, to
          gain confidence in the impact investing scene in Bangladesh.
        </p>

        <p className="">
          The second lessons learnt is around partnerships and collaborations
          that are crucial success factor in catalysing the growth of the impact
          investing community in Bangladesh. First and foremost, the partnership
          with Roots of Impact and LightCastle Partners in B-Briddhi seamlessly
          combines global expertise with local knowledge to bridge market gaps.
          Working closely with local incubators and accelerators under the
          capacity building activities has also equipped local stakeholders with
          skills to fill market gaps and support high-potential impact
          enterprises in materialising IMM and securing funding to scale their
          impact. On the transactional level, the spirit of partnership is
          reflected in the co-creation process of the impact metrics in the
          transactions. Rather than a top-down approach, in which impact targets
          are set unilaterally, the impact metrics design process has been
          curated carefully to ensure feedback from impact enterprises are
          incorporated and that they are in alignment with their own growth
          strategies.
        </p>
        <p className="pb-6">
          The concept of partnership has been pushed further when B-Briddhi
          convened the first Impact Ecosystem Workshop in 2022, sparking
          meaningful dialogue amongst entrepreneurs, incubators and investors.
          It also supported the National Advisory Board for Impact Investment,
          in developing the Bangladesh Impact Investment Strategy and Action
          Plan, and continuously advancing critical recommendations, including
          standardising impact due diligence, supporting marginalised groups,
          and promoting blended finance together with local investors and
          stakeholders. B-Briddhi also pioneered gender-lens investing in
          Bangladesh, hosting the country’s first event on the topic in 2022,
          whilst onboarding Truvalu as a local partner in furthering inclusivity
          in investment practices. The various forms of collaboration fostered a
          strong community among entrepreneurs, intermediaries, and investors
          that will sustain beyond the tenure of B-Briddhi, and laid the
          foundation for a comprehensive, sustainable impact investing framework
          in the country.
        </p>

        <p className="pb-6">
          Last but least, we learnt that local knowledge dissemination is
          essential for ecosystem building. For example, B-Briddhi has invested
          in producing resources like the Innovative Finance Toolkit, case
          studies, and a deal book on transactions. These resources lower the
          entry barrier by equipping local stakeholders with practical insights
          into navigating the impact investing landscape.
        </p>

        <p className="font-semibold">So, What’s Next?</p>
        <p className="pb-6">
          The first four years of the B-Briddhi programme introduced essential
          practices for credible impact investing in Bangladesh, focusing on
          IMM. It is clear that mainstreaming these practices will be vital to
          avoid impact-washing. Expanding IMM practices to a broader group of
          stakeholders, including capital providers, will be fundamental to
          cement an impact investing ecosystem with integrity. And this is
          particularly important at a time when Bangladesh aims at restoring the
          confidence of investors in the market.
        </p>
        <p className="pb-6">
          We are particularly excited about the gender-lens investing
          partnership with Truvalu, which ensures that accountability and
          evidence for gender impact remain central. This partnership represents
          one step in expanding rigorous impact management practices to all
          stakeholders involved in gender-lens investing.
        </p>
        <p className="pb-6">
          Looking forward, we aim to extend our effort in knowledge
          disseminations and share our successes and lessons learnt with a wider
          audience, both within and beyond Bangladesh, so that the impact
          created by B-Briddhi-supported enterprises can serve as a prototype.
          We hope these stories will inspire other entrepreneurs in other
          regions to stay committed in creating meaningful impact and
          contributing to inclusive economic and social growth.
        </p>

        <div className="mb-8">
          <img src={news171_people} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Corinne Henchoz Pignani</p>
            <p>Deputy Head of Mission/Head of Cooperation</p>
            <p>Embassy of Switzerland in Bangladesh</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
