import boardMember1 from "../../assets/images/boardMember1.jpg";
import boardMember2 from "../../assets/images/boardMember2.jpg";
import boardMember3 from "../../assets/images/boardMember3.jpg";
import boardMember4 from "../../assets/images/boardMember4.jpg";
import boardMember6 from "../../assets/images/boardMember6.jpg";
import boardMember7 from "../../assets/images/boardMember7.jpg";
import boardMember8 from "../../assets/images/boardMember8.jpg";
import boardMember9 from "../../assets/images/boardMember9.jpg";
import boardMember10 from "../../assets/images/boardMember10.jpg";
import boardMember12 from "../../assets/images/boardmember12.png";
import boardMember13 from "../../assets/images/boardMember13.jpg";
import boardMember14 from "../../assets/images/boardMember14.jpg";
import board_member31 from "../../assets/images/board_member31.png";
import boardMember100 from "../../assets/images/board100.png";
import Sucharita_Kamath from "../../assets/images/Sucharita_Kamath.png";

import secreteriat1 from "../../assets/images/secreteriat1.jpg";
import secreteriat2 from "../../assets/images/secreteriat2.png";
import secreteriat3 from "../../assets/images/secreteriat3.jpg";
import secreteriat4 from "../../assets/images/fahad.jpg";

import trust1 from "../../assets/images/trust1.png";
import trust2 from "../../assets/images/trust2.png";
import trust3 from "../../assets/images/trust3.png";
import trust4 from "../../assets/images/trust4.png";
import trust5 from "../../assets/images/trust5.png";
import trust6 from "../../assets/images/trust6.png";
import trust7 from "../../assets/images/trust7.png";

import Sami_Ahmed from "../../assets/images/Sami_Ahmed.png";
import Suresh_Bartlett_2 from "../../assets/images/Suresh_Bartlett_2.png";
import Tarique_Amin from "../../assets/images/Tarique_Amin.png";
import Bijon_Islam_2 from "../../assets/images/bijon.png";

export const boardMembersData = [
  {
    id: "01",
    name: "Chowdhury Ashik Mahmud Bin Harun",
    extraInfo: "Chair, NAB",
    designation: "Executive Chairman",
    from: "Bangladesh Investment Development Authority",
    image: boardMember4,
  },
  {
    id: "02",
    name: "Muhammad Rafiqul Islam",
    extraInfo: "",
    designation: "Chief Executive Officer",
    from: "Public Private Partnership Authority",
    image: boardMember4,
  },
  {
    id: "03",
    name: "Dr. Shaikh Shamsuddin Ahmed",
    extraInfo: "",
    designation: "Commissioner",
    from: "Bangladesh Securities and Exchange Commission",
    image: boardMember3,
  },
  {
    id: "04",
    name: "",
    extraInfo: "",
    designation: "",
    from: "Bangladesh Bank",
    image: boardMember4,
  },
  {
    id: "05",
    name: "Sami Ahmed",
    extraInfo: "",
    designation: "Managing Director & CEO",
    from: "Startup Bangladesh Limited",
    image: Sami_Ahmed,
  },

  {
    id: "06",
    name: "Asif Saleh",
    extraInfo: "",
    designation: "Executive Director",
    from: "BRAC",
    image: trust6,
  },
  {
    id: "31",
    name: "Suresh Bartlett",
    extraInfo: "",
    designation: "National Director",
    from: " World Vision Bangladesh",
    image: Suresh_Bartlett_2,
  },
  {
    id: "31",
    name: "Zahida Fizza Kabir",
    extraInfo: "",
    designation: "Chief Executive Officer",
    from: " SAJIDA Foundation",
    image: board_member31,
  },

  {
    id: "07",
    name: "Rebecca Bucherer",
    extraInfo: "",
    designation:
      "Regional Advisor for Inclusive Economic Development and Private Sector Engagement",
    from: "Swiss Agency for Development and Cooperation, Embassy of Switzerland",
    image: boardMember7,
  },
  {
    id: "101",
    name: "Sucharita Kamath",
    extraInfo: "",
    designation: "Chapter Head, South Asia",
    from: "Aspen Network of Development Entrepreneurs (ANDE)",
    image: Sucharita_Kamath,
  },
  {
    id: "08",
    name: "Barrister Anita Ghazi Rahman",
    extraInfo: "",
    designation: "Founder & Managing Partner",
    from: "The Legal Circle",
    image: trust4,
  },
  {
    id: "32",
    name: "Tarique Amin Bhuiyan",
    extraInfo: "",
    designation: "",
    from: "Independent Consultant",
    image: Tarique_Amin,
  },
  {
    id: "33",
    name: "Bijon Islam",
    extraInfo: "",
    designation: "Co-Founder & CEO",
    from: "Light Castle Partners Ltd",
    image: Bijon_Islam_2,
  },
  // {
  //   id: "100",
  //   name: "Shah A Sarwar",
  //   extraInfo: "",
  //   designation: "Former Managing Director & CEO",
  //   from: "IFIC Bank PLC",
  //   image: boardMember100,
  // },

  {
    id: "09",
    name: "Sangita Ahmed",
    extraInfo: "",
    designation: "Senior Vice President",
    from: "Bangladesh Women Chamber of Commerce and Industries",
    image: boardMember9,
  },
  {
    id: "10",
    name: "Stefan Liller",
    extraInfo: "",
    designation: "Resident Representative",
    from: "United Nations Development Programme",
    image: boardMember10,
  },
  {
    id: "12",
    name: "Martin Holtmann",
    extraInfo: "",
    designation: "Country Manager",
    from: "International Finance Corporation (IFC)",
    image: boardMember12,
  },
  {
    id: "13",
    name: "Ichiguchi Tomohide",
    extraInfo: "",
    designation: "Chief Representative",
    from: "Japan International Cooperation Agency",
    image: boardMember13,
  },
  {
    id: "14",
    name: "Arastoo Khan",
    extraInfo: "",
    designation: "Member Secretary",
    from: "NAB Bangladesh",
    image: trust1,
  },
  {
    id: "15",
    name: "Farhad Reza",
    extraInfo: "",
    designation: "Founding President",
    from: "Build Bangladesh",
    image: trust5,
  },
];

export const nabTrust = [
  {
    id: "01",
    name: "Arastoo Khan",
    extraInfo: "",
    designation: "Chairman",
    from: "Former Secretary to the Bangladesh Government",
    image: trust1,
  },
  {
    id: "02",
    name: "Sultana Afroz",
    extraInfo: "",
    designation: "Vice Chairperson",
    from: "Former Secretary, Public Private Partnership Authority",
    image: trust2,
  },
  {
    id: "03",
    name: "Niaz Rahim",
    extraInfo: "",
    designation: "Treasurer",
    from: "Managing Director, RahimAfrooz Group",
    image: trust3,
  },
  {
    id: "04",
    name: "Anita Ghazi Rahman",
    extraInfo: "",
    designation: "Secretary",
    from: "Barrister, Founder and Managing Partner, The Legal Circle",
    image: trust4,
  },

  {
    id: "05",
    name: "Farhadur Reza",
    extraInfo: "",
    designation: "Managing Trustee",
    from: "Architect-Planner, Former Australian Government Senior Executive, UNDP Advisor",
    image: trust5,
  },
  {
    id: "06",
    name: "Asif Saleh",
    extraInfo: "",
    designation: "Member",
    from: "Executive Director, BRAC",
    image: trust6,
  },
  {
    id: "07",
    name: "Sangeeta Ahmed",
    extraInfo: "",
    designation: "Member",
    from: "Senior Vice President, Bangladesh Women Chamber of Commerce and Industries",
    image: trust7,
  },
];

export const secreteriatData = [
  {
    id: "01",
    name: "Farhad Reza",
    extraInfo: "",
    designation: "Founding President",
    from: "Build Bangladesh",
    image: trust5,
  },
  {
    id: "02",
    name: "Tasvir Bin Mahabub",
    extraInfo: "",
    designation: "Executive",
    from: "NAB Bangladesh Secretariat",
    image: secreteriat2,
  },
  {
    id: "04",
    name: "Fahad-Al-Salam",
    extraInfo: "",
    designation: "Research Associate",
    from: "NAB Bangladesh Secretariat",
    image: secreteriat4,
  },
  {
    id: "03",
    name: "Ekraz Alim",
    extraInfo: "",
    designation: "Project Apprentice",
    from: "NAB Bangladesh Secretariat",
    image: secreteriat3,
  },
];
