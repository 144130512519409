import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news174.png";
import news123_creator from "../../../../assets/images/news174_people.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function MalaysiaImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
        "What you do makes a difference, and you have to decide what kind of difference you want to make." – Jane Goodall
        </p>
        <p className="pb-6">
        Every action creates an impact, and investing is no exception. Investments, especially large-scale ones, have the potential to create long-term, significant effects on both society and the environment.
        </p>
        <p className="pb-6">
        It was with this belief that a group of committed, experienced individuals came together as a taskforce under the Global Steering Group for Impact Investment (GSG Impact) to lay the groundwork for the Malaysia Impact Alliance (MYImpact). Officially recognized as a GSG Impact National Partner in October 2023, MYImpact’s purpose is to catalyze Malaysia’s impact investment ecosystem towards an impact economy, where investors and entrepreneurs are aligned to drive positive socio-economic and environmental outcomes.
        MYImpact’s mission has always been to create an inclusive and coordinated ecosystem, driving awareness and action to address critical gaps in Malaysia’s impact investing landscape. Our goal is to align the supply and demand sides of capital, ensuring that both investors and impact-driven enterprises are empowered to engage effectively. To achieve this, we needed a platform that could represent the diverse views of stakeholders and facilitate actions that increase alignment towards shared social goals.
        </p>
        <p>
        The core belief that capital, when deployed effectively, can be a powerful tool for change was central to MYImpact’s formation. The ecosystem we sought to build required alignment across diverse stakeholders, from institutional investors seeking financial returns to impact-driven enterprises in need of capital to scale their impact. With the right strategies, tools, and methodologies, investments can be directed to achieve meaningful, net-positive outcomes, such as environmental preservation, social development, nation-building, and fostering diversity and inclusion.
        </p>
        <p className="py-6">
        As MYImpact took shape, we realized that collaboration was not just important—it was essential. The five pillars of the impact investment ecosystem—government, supply of capital, demand for capital, financial intermediaries, and ecosystem enablers—each have unique perspectives and priorities. For a healthy ecosystem, these pillars must work in harmony. MYImpact’s board is composed of key leaders from these pillars. By leveraging their expertise, and engaging with both local and international organisations, MYImpact has designed and proposed strategies to facilitate more impact capital mobilised to enhance positive socio-economic and environmental outcomes.
        </p>
        <p className="pb-6">
        MYImpact’s relationship with GSG Impact has been instrumental in this process. Through our partnership with GSG Impact, MYImpact has gained access to a global network of more than 50 National Partners who are working to transform financial systems and advance ecosystems toward an impact economy. This partnership has provided invaluable resources, insights, and a knowledge-sharing platform that enables MYImpact to amplify local voices and advocate for initiatives that prioritize impact alongside financial returns.
        </p>
        <p className="">
        As MYImpact’s work has evolved, I have found that my personal journey has aligned naturally with the organization’s mission. With a family business background in healthcare, I have always believed that the business of healthcare is not just about profits but about improving health outcomes and lives. This principle became the foundation of my approach to impact investing—capital, when deployed effectively, creates value far beyond just financial returns.
        </p>
        <p className="pb-6">
        A pivotal moment in my journey occurred during a conversation with a friend from Nigeria. We were discussing the role of aid agencies, particularly in food aid and emergency relief, when she suggested, "Why can’t some of that funding be used for entrepreneurship? Funding local entrepreneurs empowers individuals and creates jobs." This idea resonated deeply with me. It highlighted that sustainability does not come from dependency, but from creating opportunities for self-sufficiency. Investing in businesses—especially those that foster entrepreneurship and local job creation—can empower communities to rise above poverty while generating financial returns.
        </p>
        <p className="pb-6">
        This principle lies at the heart of MYImpact’s initiatives to empower impact-driven enterprises. These businesses are uniquely positioned to address societal challenges while generating economic value. Impact investing, when aligned with philanthropy, has the potential to drive social change, create jobs, and improve lives. With aligned investors, impact-driven enterprises will have the resources to sustain and scale their impact, just as entrepreneurs in healthcare or other sectors need the right capital to create value.
        </p>
        <p className="">
        As we continue to build Malaysia’s impact investment ecosystem, we recognize that there are still gaps and challenges in the mobilization of impact capital. MYImpact’s role will be to bridge these gaps, designing initiatives that prioritize coordination and alignment, and highlighting challenges across all pillars to be addressed collectively. The partnership with GSG Impact will remain central to our work, helping us engage with a global community of like-minded organizations committed to creating economies that prioritize impact at the heart of every decision. Through MYImpact, we aim to contribute to a vibrant, scalable, and impactful ecosystem in Malaysia that will benefit investors, entrepreneurs, and communities, ensuring a sustainable and better future for generations to come.
        </p>
       

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Dr. Melissa Foo</p>
            <p>Chief Executive Officer
           </p>
            <p> Malaysia Impact Alliance (MyImpact)</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
